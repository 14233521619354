import cash from "cash-dom";
import store from "@/store";

export default function paymentStatusColumn() {
	const translation = {
		en: require('../../locales/en.json'),
		nl: require('../../locales/nl.json'),
		fr: require('../../locales/fr.json'),
		de: require('../../locales/de.json'),
		es: require('../../locales/es.json'),
		sk: require('../../locales/sk.json')
	}

	return {
		title: 'PAYMENT',
		field: 'payment',
		hozAlign: "center",
		cssClass: 'text-centered',
		width: '8%',
		formatter(cell) {
			let status = cell.getData().paymentStatusTooltip;
			let toolTip = '';
			if (!(translation[store.state.locale].paymentStatusesTooltips === undefined && status === '')) {
				toolTip = translation[store.state.locale].paymentStatusesTooltips[status];
			}

			const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                    						<div class="customer-name-outer payment-statuses">
                                            					${cell.getData().payment}
                                            					<span class="tooltip tooltip-billing payment-status ${status}">${toolTip}</span>
                                     						</div>
                                    					</div> `);
			return a[0];
		}
	}
}