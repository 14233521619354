import i18n from "@/i18n";

export default function calcChannels(channels) {
	let channelsColumn = ''
	if (channels === undefined) return i18n.t('columns.channelsRow.noChannel')
	else {
		if (channels.attachEmail) channelsColumn += ', ' +  i18n.t('columns.channelsRow.attachEmail')
		if (channels.linkEmail) channelsColumn += ', ' + i18n.t('columns.channelsRow.linkEmail')
		if (channels.edi) channelsColumn += ', ' + i18n.t('columns.channelsRow.edi')
		if (channels.print) channelsColumn += ', ' + i18n.t('columns.channelsRow.print')
	}

	if (channelsColumn.trim() === '') return i18n.t('columns.channelsRow.noChannel')
	channelsColumn = channelsColumn.slice(2)
	return channelsColumn
}
