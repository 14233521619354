import axios from "axios"

export default {
	getUblDocumentData(context, docId) {
		let url = context.rootState.domainName + context.rootState.API.ublDetailData.replace(":documentId", docId)

		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
			context.commit('setUblDocumentData', res.data.data.peppolDoc, {root: true})
		}).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},
	async getSmpParticipants(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.smpParticipants
		return await axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		)
	},

	checkOnPeppol(context, payload) {
		let url = window.location.hostname.includes('localhost') || window.location.hostname.includes('staging') ?
			context.rootState.API.checkOnPeppol.replace(":vatNumber", payload) :
			context.rootState.API.checkOnPeppolPROD.replace(":vatNumber", payload)
		
		return axios.get(url)
			.then(() => {
				context.commit("setVatIsOnPeppol", {isOnPeppol: true, status: "ok"}, {root: true})
				context.commit("setPeppolIsChecked", true, {root: true})
				return 200
			})
			.catch(error => {
				if (error.response.status === 404) {
					context.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "ok"}, {root: true})
				} else {
					context.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "error"}, {root: true})
				}
				context.commit("setPeppolIsChecked", true, {root: true})
				return error.response.status
			})
	},
	validateUBLDocument(context, formData) {
		let url = context.rootState.domainName
		return axios.post(url + context.rootState.API.validateUBL, formData, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then((result) => {
			context.commit('setValidationResponse', result.data, {root: true})
			return result.data
		}).catch((error) => {
			console.log(error)
			throw error
		})
	},
}