import userGroups from "@/userGoups";
import store from "../../store";

export default function amountSalary() {
	if (store.state.docType === "SALARY") {
		if (this.columns.find(col => col.title === "AMOUNT") === undefined && this.$route.name === 'public'
			|| (this.$route.name === 'overview' && !(userGroups.optipostNames.includes(store.state.currentUserData.data.group)))) {
			if (store.state.overviewTable.salary.displayAmountColumn) {
				return {
					title: 'AMOUNT',
					field: 'amount',
					cssClass: 'text-centered',
					width: 140,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                              					${cell.getData().amount}
                        					</div>`
					}
				}
			}
		}
	}
}