<template>
	<pop-up-window
		:show="showDialog"
		:showClose=false
		class="confirmation"
		:stick-to-scroll-position="stickToScrollPosition"
		:top10=true
	>
		<template #title>
			<h1 class="text-2xl ">{{ $t(title) }}</h1>
		</template>
		<template #content>
			<div class="flex flex-col">
				<h1 :class="{'text-center' : warning === null, 'ml-8': warning !== null}">
					<i class="icon-notification info-color pr-2"></i>{{ description }}
				</h1>
				<h1 v-if="warning !== null" style="margin-left: 59px">
					{{ warning }}
				</h1>
			</div>


			<div class="btn-content mt-2 mb-10 grey-line">
				<div>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="approveAction">
						{{ $t('buttons.yes') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancelAction">
						{{ $t('buttons.no') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow.vue"
export default {
	components: {PopUpWindow},
	emits: ['approveAction', 'cancelAction'],
	props: {
		showDialog: {
			type: Boolean,
			required: true
		},
		description: {
			type: String,
			required: true
		},
		warning: {
			type: String,
			required: false,
			default: null
		},
		stickToScrollPosition: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false,
			default: 'confirmation.header'
		}
	},
	methods: {
		approveAction() {
			this.$emit('approveAction')
		},
		cancelAction() {
			this.$emit("cancelAction")
		},
	}
}
</script>

<style scoped>
.confirmation {
	z-index: 300;
}
</style>