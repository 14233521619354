<template>
	<pop-up-window
		:show="this.$store.state.viewUblDetail"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed="true"
		:position-absolute="false"
		:stickToScrollPosition="true"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('ubl.viewUblDetail') }}</h1>
			<h2>{{ ublDetail.id }}</h2>
			<h2 v-if="userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)">
				{{ ublDetail.docId }}
			</h2>
		</template>
		<template #content>
			<div v-if="ublDetail.edi" class="divider container">
				<h2 class="edi-provider"> {{ $t('documentDetail.documentStatusesInfo.ediProvider') }}
					{{ ublDetail.ediProvider }}
				</h2>
				<div v-for="(item, index) in ediStatuses" :key=index>
					<div>
						<div style="display: flex; flex-direction: row; align-items: center">
							<span
								class="material-icons" style="padding-right: 0; font-size: 21px "
								:style="{color: item.error? 'darkred' : '#215893'}"
							>
								error_outline
							</span>
							<span class="description">{{ item.status }}</span>
							<i class="icon-calendar" style="padding: 0"></i>
							<span class="description">{{ item.time }}</span>
						</div>
						<p v-if="item.message" class="ml-2 description">{{item.message}}</p>
					</div>
				</div>
			</div>

			<div class="grid grid-rows-1 justify-items-center my-6 grey-top-border">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../UI/PopUpWindow"
import i18n from "../../i18n"
import moment from "moment"
import userGroups from "../../userGoups"

export default {
	name: "ViewUblDetail",
	components: {PopUpWindow},

	computed: {
		userGroups() {
			return userGroups
		},
		ublDetail() {
			return this.$store.state.ublDocumentData
		},
		ediStatuses() {
			let ediStatuses = []

			this.ublDetail.edi.forEach(status => {
				let time
				if (typeof status.time === 'object') {
					time = moment(status.time).format('DD/MM/yyyy HH:mm:ss')
				} else {
					time = moment(status.time, 'DD/MM/YYYY h:mm:ss A').format('DD/MM/YYYY HH:mm:ss')
				}

				let tempItem = {
					time: time,
					status: '',
					error: false
				}

				if (status.message) {
					tempItem.message = status.message
				}
				switch (status.status) {
					case "RESENT":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.resent')
						break
					case "SENT":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.sent')
						break
					case "PROCESSED":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.processed')
						break
					case "ERROR":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.error')
						tempItem.error = true
						break
					case "NOT_SENT":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.notSent')
						tempItem.error = true
						break
					case "ACKNOWLEDGED":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.acknowledge')
						tempItem.error = false
						break
					case "IN_PROCESS":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.inProcess')
						tempItem.error = false
						break
					case "UNDER_QUERY":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.underQuery')
						tempItem.error = false
						break
					case "COND_ACCEPTED":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.condAccepted')
						tempItem.error = false
						break
					case "REJECTED":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.rejected')
						tempItem.error = true
						break
					case "ACCEPTED":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.accepted')
						tempItem.error = false
						break
					case "PAID":
						tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.paid')
						tempItem.error = false
						break
				}

				if (status.status === "FORWARDED") {
					tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.forwarded')
					if (status.forwardedTo) {
						let key = 'documentDetail.documentStatusesInfo.ediStatuses.forwardedTo.' + status.forwardedTo
						if (status.forwardedTo === "email" && status.email) {
							tempItem.status = tempItem.status + ' ' + i18n.t('general.toPreposition') + ' ' + status.email + ' ' + i18n.t(key)
						} else {
							tempItem.status = tempItem.status + ' ' + i18n.t('general.toPreposition') + ' ' + i18n.t(key)
						}
					}
					tempItem.error = false
				}

				if (status.status === "RECEIVED") {
					tempItem.status = i18n.t('documentDetail.documentStatusesInfo.ediStatuses.received')
					tempItem.error = false
				}
				ediStatuses.push(tempItem)
			})
			return ediStatuses
		}
	},
	methods: {
		close() {
			this.$store.commit('setUblDocumentData', {})
			this.$store.commit('setViewUblDetail', false)
		}
	}
}
</script>

<style scoped>
.divider {
	margin-top: calc(1 * var(--generalPaddings));
}

.description {
	color: var(--theme-primary-500);
	padding-left: 5px;
	font-size: 14px;
	padding-right: 5px;
}

i {
	color: var(--infoColor);
	padding-bottom: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
}
</style>