<template>
	<div>
		<div class="intro-y statuses-table">
			<statuses-table
				:supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
				:requestUrl="this.requestUrl"
			/>
		</div>
	</div>
</template>

<script>
import StatusesTable from "./statuses/StatusesTable"
import printStatuses from '../../locales/en.json'
import i18n from '../../i18n'
import userGroups from "../../userGoups"

export default {
	components: {
		StatusesTable
	},
	mounted() {
		this.setPrintStatusesList()
	},
	data() {
		return {
			requestUrl: this.$store.state.domainName + this.$store.state.API.postalService,
			printStatuses: printStatuses.dropdowns.printStatuses,
			statusesList: [],
		}
	},
	watch: {
		locale() {
			this.setTranslations()
		}
	},
	computed: {
		userGroups() {
			return userGroups
		},
		locale() {
			return this.$store.state.locale
		}
	},
	methods: {
		setPrintStatusesList() {
			for (let item in this.printStatuses) {
				this.statusesList.push(item)
			}
		},
		setTranslations() {
			this.statusesList.forEach(item => {
				let cellClass = '.' + item
				let cells = document.querySelectorAll(cellClass)
				let key = 'dropdowns.printStatuses.' + item
				cells.forEach(cell => {
					cell.innerHTML = i18n.t(key)
				})
			})
		}
	}
}
</script>