<template>
	<transition name="dialog" v-if="currentUserDataIsReady ">
		<div>
			<general-notification></general-notification>
			<page-container class="page-content">
				<top-banner>
					<div class="w-9/12">
						<h1 class="text-3xl text-center">{{ $t('management.header.title') }}</h1>
					</div>
				</top-banner>
				<div class=" box pl-2 pr-2 pt-5 pb-5 mt-5" v-if="checkResetPassword">
					<div class="button-container">
						<div class="button-container-inner">
							<div v-for="(type, index) in managementTypes" :key="index">
								<button
									class="btn custom-button"
									:class="{'inactive' : inactiveItem(type.value)} "
									@click=setActiveManagementType(type.value)
									v-if=displayButton(type.value)
								>
									{{ type.label }}
								</button>
							</div>
						</div>
					</div>
					<div class="component-container">
						<manage-user-content v-if="displayComponent('users')"></manage-user-content>
						<manage-receiver-content v-if="displayComponent('receivers')"></manage-receiver-content>
						<customize-overview
							v-if="displayComponent('customizeTables')"
							@cancel-changes="cancelCustomizeOverviewChanges"
						/>
						<ReportSettings v-if="displayComponent('reportSettings')"></ReportSettings>
						<manage-mails-content v-if="displayComponent('mails')"></manage-mails-content>
						<departments-overview v-if="displayComponent('departments')"></departments-overview>
					</div>
				</div>
			</page-container>
		</div>
	</transition>
</template>

<script>
import PageContainer from "../components/PageContainer"
import TopBanner from "../components/TopBanner"
import routerData from "../routerData"
import ManageUserContent from "../components/management/users/ManageUserContent.vue"
import ManageReceiverContent from "../components/management/Receivers/ManageReceiverContent"
import ManageMailsContent from "../components/management/mails/ManageMailsContent"
import userGroups from "../userGoups"
import i18n from '../i18n'
import store from "../store"
import GeneralNotification from "../components/UI/GeneralNotification"
import DepartmentsOverview from "../components/management/departments/DepartmentsOverview.vue"
import ReportSettings from "../components/reports/ReportSettings.vue"
import CustomizeOverview from "../components/management/customizeTables/CustomizeOverview.vue"

const newLoginPage = window.newLoginPage
export default {
	components: {
		ReportSettings,
		DepartmentsOverview,
		GeneralNotification,
		TopBanner, PageContainer, ManageUserContent, ManageReceiverContent, ManageMailsContent, CustomizeOverview
	},
	created() {
		this.$store.dispatch('checkAcceptedTos', {requiresAuth: this.$route.meta.requiresAuth})
		this.checkUser()
		this.passwordReset()
	},
	data() {
		return {
			currentUserDataIsReady: this.$store.state.currentUserData.data.id,
			routerData: routerData,
			displayPage: false,
			userGroups: userGroups,
			i18n: i18n,
			activeType: this.$store.state.managementActiveTab,
			displayResetPassword: this.$store.state.resetPassword
		}
	},
	computed: {
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))
		},
		currentUserData() {
			return this.$store.state.currentUserData.data
		},
		locale() {
			return this.$store.state.locale
		},
		managementTypes() {
			return [
				{label: i18n.t('management.types.users'), value: "users"},
				{label: i18n.t('management.types.receivers'), value: "receivers"},
				{label: i18n.t('management.types.customizeTables'), value: "customizeTables" },
				{label: i18n.t('management.types.reportSettings'), value: 'reportSettings'},
				{label: i18n.t('management.types.mails'), value: "mails"},
				{label: i18n.t('management.types.departments'), value: "departments"},
			]
		}
	},
	watch: {
		locale() {
			let tooltipsData = [
				{cellName: "view-user", translationField: 'viewUser'},
				{cellName: "edit-user", translationField: 'editUser'},
				{cellName: "only-nymdrop", translationField: 'onlyNymdrop'},
				{cellName: "manage-authorization", translationField: 'manageAuthorization'},
				{cellName: "not-available", translationField: 'notAvailable'},
				{cellName: "no-email", translationField: 'noEmail'},
				{cellName: "reset-password", translationField: 'resetPassword'},
				{cellName: "delete-user", translationField: 'deleteUser'},
				{cellName: "edit-receiver-choice", translationField: 'editReceiverChoice'},
				{cellName: "view-receiver-choice", translationField: 'viewReceiverChoice'},
				{cellName: "delete-receiver-choice", translationField: 'deleteReceiverChoice'},
				{cellName: "click-to-copy", translationField: 'clickToCopy'},
				{cellName: "reactivate-email", translationField: 'reactivateEmail'},
				{cellName: "spam-complaint", translationField: 'spamComplaint'},
				{cellName: "email-corrected", translationField: 'emailCorrected'},
				{cellName: "edit-department", translationField: 'editDepartment'},
				{cellName: "delete-department", translationField: 'deleteDepartment'},
				{cellName: "add-department-user", translationField: 'addDepartmentUser'},
				{cellName: "delete-department-user", translationField: 'deleteDepartmentUser'},
				{cellName: "no-department-user", translationField: 'noDepartmentUser'},
				{cellName: "view-department-user", translationField: 'viewDepartmentUser'},
			]
			tooltipsData.forEach(tooltip => {
				this.changeTooltipsTranslation(tooltip.cellName, tooltip.translationField)
			})
		}
	},
	methods: {
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) {
				this.$store.commit("setDisplayResetOwnPassword", true)
			}
		},
		displayButton(button) {
			if (button === 'receivers') {
				return !!(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| (userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						&& this.$store.state.supplierGroup.data.channelsConfig?.docImage?.channelChoice?.displayOnPortal
					))
			}

			if (button === "mails") {
				return userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| (userGroups.supplierNames.includes(store.state.currentUserData.data.group))
			}
			if (button === "departments") {
				return !!((this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.generalPost)
					&& (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || (store.state.currentUserData.data.group === "SupplierGroupSuperAdmin")))
			}
			if (button === "reportSettings") {
				return userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || this.$store.state.currentUserData.data.group === "SupplierGroupSuperAdmin"
			}
			if (button === "customizeTables") {
				return this.$store.state.docType === "BILLING"
					&& userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || this.$store.state.currentUserData.data.group === userGroups.SupplierGroupSuperAdmin.name
			}
			return true
		},
		displayComponent(managementType) {
			return managementType === this.activeType
		},
		inactiveItem(type) {
			return type !== this.activeType
		},
		setActiveManagementType(item) {
			this.activeType = item
		},
		changeTooltipsTranslation(cellName, cellTranslationField) {
			let cellClass = '.' + cellName

			let cells = document.querySelectorAll(cellClass)
			let key = 'tooltips.' + cellTranslationField
			if ((cells.length) !== 0) {
				cells.forEach((cell) => {
					cell.innerHTML = i18n.t(key)
				})
			}
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (this.userGroups.receiverUserNames.includes(this.$store.state.currentUserData.data.group)
					|| this.$store.state.currentUserData.data.group === 'SupplierLimitedSubUser'
					|| this.$store.state.currentUserData.data.group === 'SupplierSubUser'
				) {
					this.$router.push({path: routerData.noAccess.link})
				}
			}
		},
		cancelCustomizeOverviewChanges() {
			this.activeType = ''
			this.displayComponent('')
			setTimeout(() => {
				this.activeType = "customizeTables"
				this.displayComponent('customizeTables')
			}, 10)
		}
	}
}
</script>

<style scoped>
.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(20, auto);
	grid-template-rows: 100%;
}

.custom-button {
	margin-left: 10px;
}

.component-container {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}

@media only screen and (max-width: 750px) {
	.button-container > div > div {
		grid-template-columns: 100% !important;
	}
}
</style>