<template>
	<div>
		<div class="document-info">
			<div class="mb-2">
				<div class="status-title" :class="{'displayNone': noHeader}">
					<i class="icon-bell info-color"></i>
					<h1 class="text-xl">{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}</h1>
				</div>
			</div>
			<div class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.documentGeneralInfo') }}</h2>
				<!--created-->
				<div class="flex flex-row items-center">
					<label-with-icon
						:margin-left="10"
						icon-name="calendar_month"
						:outlined="false"
						:label="docData.docStatuses.createdOn"
					/>
				</div>
				<!--Signed-->
				<div
					v-if="docStatusData.signed"
					class="flex flex-row items-center"
				>
					<label-with-icon
						:margin-left="10"
						icon-name="edit"
						:outlined="false"
						:label="$t('documentDetail.documentStatusesInfo.documentIsSigned')"
					/>
				</div>

				<!--Hold release-->
				<div v-if="showHoldRelease && textHoldRelease !== ''" class="flex flex-row items-center">
					<label-with-icon
						:margin-left="10"
						icon-name="edit_document"
						:outlined="false"
						:label="textHoldRelease"
					/>
				</div>
				<!--Viewed-->
				<div v-if="docData.viewedBy" class="flex flex-row items-center">
					<label-with-icon
						:margin-left="10"
						icon-name="person"
						:label="docData.viewedBy.name"
						:outlined="false"
					/>
					<label-with-icon
						:margin-left="5"
						icon-name="calendar_month"
						:label="docData.viewedBy.time"
						:outlined="false"
					/>
				</div>
			</div>

			<div v-if="docData.handledInfo && displayHandledError" class="mb-2">
				<h2 data-test="txtTitleErrorHandling" class="text-base font-bold">
					{{ $t('documentDetail.documentStatusesInfo.errorHandled') }}</h2>
				<div class="flex flex-row items-center">
					<label-with-icon
						:margin-left="10"
						icon-name="mail"
						:label="docData.handledInfo.handledBy"
					/>
					<label-with-icon
						:margin-left="5"
						icon-name="calendar_month"
						:label="docData.handledInfo.handledAt"
						:outlined="false"
					/>
				</div>
				<div v-if="docData.handledInfo.reason" style="display: flex; flex-direction: row;">
					<label-with-icon
						:margin-left="10"
						icon-name="error_outline"
						:label="docData.handledInfo.reason"
						data-test="txtReason"
						icon-severity-color="info"
					/>
				</div>
			</div>
			<div v-if="this.$store.state.mandateExists" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.mandate') }}</h2>
				<div>
					<i class="info-color icon-notification padding-left"/>
					{{ $t('documentDetail.documentStatusesInfo.mandateExists') }}
				</div>
			</div>

			<div v-if="docData.barCode.registeredMail && docData.barCode.code && displayTracking" class="mb-2">
				<h2 class="text-base font-bold">
					{{ $t('documentDetail.documentStatusesInfo.registeredMail.trackMail') }}
				</h2>
				<div>
					<div>
						<document-info-extended
							:prinInfo=docData.barCode.code
							:printCSS=true
						>
							<template #print>
								<i class="info-color icon-earth">
									<a target="_blank" :href='registeredMailLink' class="description">https://track.bpost.cloud...</a>
								</i>
							</template>
						</document-info-extended>
					</div>
				</div>
			</div>

			<!-- AttachmentMails -->
			<div
				v-if="(this.$store.getters.getDocumentDetailData.held === undefined || this.$store.getters.getDocumentDetailData.held === false) && docData.emailWithAttachment"
				class="mb-2"
			>
				<h2 class="text-base font-bold">
					{{ $t('documentDetail.documentStatusesInfo.documentWithAttachment') }}
				</h2>
				<div v-if="docData.emailWithAttachment.sentEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentSent')"
							icon-severity-color="info"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-for="(item, index) in docData.emailWithAttachment.sentEmail" :key=index>
						<div class="flex flex-row items-center">
							<label-with-icon
								:margin-left="20"
								icon-name="mail"
								:label="item.email"
							/>
							<label-with-icon
								:margin-left="5"
								icon-name="calendar_month"
								:label="item.processedOn"
								:outlined="false"
							/>
						</div>
					</div>
				</div>
				<div v-if="docData.emailWithAttachment.deliveredEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentDelivered')"
							icon-severity-color="success"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-for="(item, index) in docData.emailWithAttachment.deliveredEmail" :key=index>
						<div class="flex flex-row items-center">
							<label-with-icon
								:margin-left="20"
								icon-name="mail"
								:label="item.email"
							/>
							<label-with-icon
								:margin-left="5"
								icon-name="calendar_month"
								:label="item.processedOn"
								:outlined="false"
							/>
						</div>
					</div>
				</div>
				<div v-if="docData.emailWithAttachment.openEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentOpened')"
							icon-severity-color="success"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-for="(item, index) in docData.emailWithAttachment.openEmail" :key=index>
						<div class="flex flex-row items-center">
							<label-with-icon
								:margin-left="20"
								icon-name="mail"
								:label="item.email"
							/>
							<label-with-icon
								:margin-left="5"
								icon-name="calendar_month"
								:label="item.time"
								:outlined="false"
							/>
						</div>
					</div>
				</div>

				<div v-if="docData.emailWithAttachment.spam.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentSpam')"
							icon-severity-color="error"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-for="(item, index) in docData.emailWithAttachment.spam" :key=index>
						<div>
							<div class="flex flex-row items-center">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.time"
									:outlined="false"
								/>
							</div>
							<div v-if="displayOnPublic">
								<div class="reactivate-email" v-if="displayReactivateEmail">
									<div class="email-info justify-start" v-if="isInPostMark('attachSpam', item.email)">
										{{ $t('general.spamMailIsInPostMark') }}
									</div>
									<!--									<div class="email-info justify-start"-->
									<!--										 v-if="!isInPostMark('attachSpam', item.email)">-->
									<!--										{{ $t('general.inactiveMailIsNotInPostMark') }}-->
									<!--									</div>-->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.emailWithAttachment.errorEmail.length">
					<div v-if="isOutOfOffice('emailWithAttachment')">
						<div>
							<label-with-icon
								:margin-left="10"
								icon-name="warning_amber"
								:label="$t('documentDetail.documentStatusesInfo.documentOutOfOfficeWarning')"
								icon-severity-color="warning"
								:font-size-label="16"
								:font-size-icon="20"
							/>
						</div>
						<div v-for="(item, index) in docData.emailWithAttachment.errorEmail" :key=index>
							<div v-if="item.description.includes('Out of Office')">
								<div class="flex flex-row">
									<label-with-icon
										:margin-left="20"
										icon-name="mail"
										:label="item.email"
										icon-severity-color="info"
									/>
									<label-with-icon
										:margin-left="5"
										icon-name="calendar_month"
										:label="item.errorOn"
										icon-severity-color="info"
										:outlined="false"
									/>
								</div>
								<div class="flex flex-row ml-11">
									<p>{{ item.name }}:</p>
									<p class="ml-0.5">{{
											$t(translateMailErrors(item.errorDescription, item.email)) !== "" ? $t(translateMailErrors(item.errorDescription, item.email)) : item.errorDescription
										}}</p>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div>
							<label-with-icon
								:margin-left="10"
								icon-name="error_outline"
								:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentNotDelivered')"
								icon-severity-color="error"
								:font-size-label="16"
								:font-size-icon="20"
							/>
						</div>
						<div v-for="(item, index) in docData.emailWithAttachment.errorEmail" :key=index>
							<div v-if="!item.description.includes('Out of office')">
								<div class="flex flex-row">
									<label-with-icon
										:margin-left="20"
										icon-name="mail"
										:label="item.email"
									/>
									<label-with-icon
										:margin-left="5"
										icon-name="calendar_month"
										:label="item.errorOn"
										:outlined="false"
									/>
								</div>
								<p v-if="!displayNotification(item.name)" class="ml-11">
									{{
										$t(translateMailErrors(item.name, item.email)) !== "" ? $t(translateMailErrors(item.name, item.email)) : item.name
									}}
								</p>
								<p v-else class="ml-11">
									{{!isInPostMark('attachBounces', item.email) ? $t(translateMailErrors(item.name, item.email)) : $t(translateMailErrors(item.name, item.email)).split(' ').slice(0, -1).join(" ") }}
									<span v-if="isInPostMark('attachBounces', item.email)" @click="deleteSuppression(item.email)">
										<button style="text-decoration: underline; color: #215893">
											{{$t(translateMailErrors(item.name, item.email)).split(' ').pop().replace('.', '') }}
										</button>.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.emailWithAttachment.inactiveEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithAttachmentInactive')"
							icon-severity-color="error"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>

					<div v-for="(item, index) in docData.emailWithAttachment.inactiveEmail" :key=index>
						<div>
							<div class="flex flex-row">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
									icon-severity-color="info"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.errorOn"
									icon-severity-color="info"
									:outlined="false"
								/>
							</div>
							<div class="flex flex-row ml-11">
								<div>
									<p class="ml-0.5">
										{{
											item.errorDescription.toLowerCase().includes('attachments too big') ? $t('emailErrors.attachmentTooBig') : inactiveErrorReason(item.errorReason, 'attachInactive', item.email)
										}}
										<button
											v-if="isInPostMark('attachInactive', item.email) && checkReason('attachInactive', item.email, 'HardBounce')"
											@click="deleteSuppression(item.email)"
											style="text-decoration: underline; color: #215893"
										>
											({{ $t('buttons.reactivateEmail') }})
										</button>
									</p>
								</div>
							</div>

							<div v-if="displayOnPublic">
								<div
									class="flex"
									v-if="displayReactivateEmail && isInactiveRecipient(item.errorReason)"
								>
									<div
										class="email-info justify-start"
										v-if="!isInPostMark('attachInactive', item.email)"
									>
										<label-with-icon
											:margin-left="20"
											:center-icon="false"
											icon-name="error_outline"
											:label="$t('general.inactiveMailIsNotInPostMark')"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="this.$store.getters.getDocumentDetailData.held === true && docData.emailWithAttachment">
				<hold-information
					:title="$t('documentDetail.documentStatusesInfo.documentWithAttachment')"
					:description=" $t('documentDetail.documentStatusesInfo.documentWithLinkHeld')"
					:information-receiver-text="$t('documentDetail.documentStatusesInfo.documentReceivers')"
					:receivers-data="docData.emailWithAttachment.sentEmail"
				/>
			</div>
			<!-- Email link -->
			<div
				v-if="(this.$store.getters.getDocumentDetailData.held === undefined || this.$store.getters.getDocumentDetailData.held === false) && docData.linkEmail"
				class="mb-2"
			>
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.documentWithLink') }}</h2>
				<div v-if="docData.linkEmail.sentEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithLinkSent')"
							icon-severity-color="success"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-for="(item, index) in docData.linkEmail.sentEmail" :key=index>
						<div>
							<div class="flex flex-row items-center">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.processedOn"
									:outlined="false"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.linkEmail.deliveredEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithLinkDelivered')"
							icon-severity-color="success"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>

					<div v-for="(item, index) in docData.linkEmail.deliveredEmail" :key=index>
						<div>
							<div class="flex flex-row items-center">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.processedOn"
									:outlined="false"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.linkEmail.openEmail.length">
					<div>
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithLinkOpened')"
							icon-severity-color="success"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>

					<div v-for="(item, index) in docData.linkEmail.openEmail" :key=index>
						<div>
							<div class="flex flex-row items-center">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.time"
									:outlined="false"
								/>
							</div>
						</div>
					</div>
				</div>

				<div v-if="docData.linkEmail.spam.length">
					<div class="align-icon">
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							icon-severity-color="error"
							:label=" $t('documentDetail.documentStatusesInfo.documentWithLinkSpam')"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>

					<div v-for="(item, index) in docData.linkEmail.spam" :key=index>
						<div>
							<div class="flex flex-row items-center">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.time"
									:outlined="false"
								/>
							</div>
							<div v-if="displayOnPublic">
								<div class="reactivate-email" v-if="displayReactivateEmail">
									<div class="email-info justify-start" v-if="isInPostMark('linkSpam', item.email)">
										<i class="icon icon-notification info"> </i>
										{{ $t('general.spamMailIsInPostMark') }}
									</div>
									<div class="email-info justify-start" v-if="!isInPostMark('linkSpam', item.email)">
										<i class="icon icon-notification info"> </i>
										{{ $t('general.inactiveMailIsNotInPostMark') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.linkEmail.errorEmail.length">
					<div v-if="isOutOfOffice('linkEmail')" class="align-icon">
						<label-with-icon
							:margin-left="10"
							icon-name="warning_amber"
							:label="$t('documentDetail.documentStatusesInfo.documentOutOfOfficeWarning')"
							icon-severity-color="warning"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>
					<div v-else class="align-icon">
						<label-with-icon
							:margin-left="10"
							icon-name="error_outline"
							:label="$t('documentDetail.documentStatusesInfo.documentWithLinkNotDelivered')"
							icon-severity-color="error"
							:font-size-label="16"
							:font-size-icon="20"
						/>
					</div>

					<div v-for="(item, index) in docData.linkEmail.errorEmail" :key=index>
						<div>
							<div class="flex flex-row">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.errorOn"
									:outlined="false"
								/>
							</div>
							<p v-if="!displayNotification(item.name)" class="ml-11">
								{{$t(translateMailErrors(item.name, item.email)) !== "" ? $t(translateMailErrors(item.name, item.email)) : item.name }}
							</p>
							<p v-else class="ml-11">
								{{!isInPostMark('linkBounces', item.email) ? $t(translateMailErrors(item.name, item.email)) : $t(translateMailErrors(item.name, item.email)).split(' ').slice(0, -1).join(" ") }}
								<span v-if="isInPostMark('linkBounces', item.email)" @click="deleteSuppression(item.email)">
									<button style="text-decoration: underline; color: #215893">
										{{$t(translateMailErrors(item.name, item.email)).split(' ').pop().replace('.', '') }}
									</button>.
								</span>
							</p>

							<div v-if="displayOnPublic" style="margin-left: 20px;">
								<div class="reactivate-email" v-if="displayReactivateEmail">
									<div
										class="email-info margin0"
										v-if="!isInPostMark('linkBounces', item.email) && displayNotification(item.name)"
									>
										<label-with-icon
											icon-name="error_outline"
											:label="$t('general.inactiveMailIsNotInPostMark')"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="docData.linkEmail.inactiveEmail.length">
					<div class="align-icon">
						<span class="material-icons" style="color: darkred">error_outline</span>
						<h2 class="text-base mt-2 ">
							{{ $t('documentDetail.documentStatusesInfo.documentWithLinkInactive') }}
						</h2>
					</div>
					<div v-for="(item, index) in docData.linkEmail.inactiveEmail" :key=index>
						<div>
							<div class="flex flex-row">
								<label-with-icon
									:margin-left="20"
									icon-name="mail"
									:label="item.email"
								/>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.errorOn"
									:outlined="false"
								/>
							</div>
							<p class="ml-11">
								{{ inactiveErrorReason(item.errorReason, "linkInactive", item.email) }}
								<button
									@click="deleteSuppression(item.email)"
									v-if="isInPostMark('linkInactive', item.email) && checkReason('linkInactive', item.email, 'HardBounce')"
									style="text-decoration: underline; color: #215893"
								>
									({{ $t('buttons.reactivateEmail') }})
								</button>
							</p>
							<div v-if="displayOnPublic">
								<div
									class="reactivate-email"
									v-if="displayReactivateEmail && isInactiveRecipient(item.errorReason)"
								>
									<div
										class="email-info justify-start"
										v-if="isInPostMark('linkInactive', item.email)"
									>
									</div>

									<div
										class="email-info justify-start"
										v-if="!isInPostMark('linkInactive', item.email)"
									>
										<label-with-icon
											icon-name="error_outline"
											:label="$t('general.inactiveMailIsNotInPostMark')"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="this.$store.getters.getDocumentDetailData.held === true && docData.linkEmail">
				<hold-information
					:title="$t('documentDetail.documentStatusesInfo.documentWithLink')"
					:description=" $t('documentDetail.documentStatusesInfo.documentWithLinkHeld')"
					:information-receiver-text="$t('documentDetail.documentStatusesInfo.documentReceivers')"
					:receivers-data="docData.linkEmail.sentEmail"
				/>
			</div>
			<!-- edi -->
			<div
				class="mb-2"
				v-if="(this.$store.getters.getDocumentDetailData.held === undefined || this.$store.getters.getDocumentDetailData.held === false) && docData.edi"
			>
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.ediStatuses.title') }}</h2>
				<h2 class="edi-provider">
					{{ $t('documentDetail.documentStatusesInfo.ediProvider') }}{{ docData.ediProvider }}
				</h2>
				<div v-for="(item, index) in ediStatuses" :key=index>
					<div>
						<div :class="{'flex flex-row' : !item.status.includes('tempFailure')}">
							<label-with-icon
								:margin-left="20"
								icon-name="info"
								:label="i18n.t(item.status)"
								:icon-severity-color="item.error ? 'error' : 'info'"
							/>
							<div class="flex flex-row align-items-center">
								<div v-if="item.message && item.status.includes('tempFailure')">
									<p style="margin-left: 20px">{{ item.message }}</p>
								</div>
								<label-with-icon
									:margin-left="5"
									icon-name="calendar_month"
									:label="item.time"
									:outlined="false"
								/>
							</div>
						</div>
						<div v-if="item.message && !item.status.includes('tempFailure')">
							<p style="margin-left: 20px">{{
									$t(translateEdiErrors(item.message)) !== "" ? $t(translateEdiErrors(item.message)) : item.message
								}}
								{{
									item.message.match(regexEdiParticipant) !== null ? item.message.match(regexEdiParticipant)[1] : ""
								}}</p>
						</div>

					</div>
				</div>
			</div>
			<div v-if="this.$store.getters.getDocumentDetailData.held === true && docData.edi">
				<hold-information
					:title="$t('documentDetail.documentStatusesInfo.ediStatuses.title')"
					:description="$t('documentDetail.documentStatusesInfo.ediStatuses.onHold')"
					:information-receiver-text="$t('documentDetail.documentStatusesInfo.ediStatuses.chosenPeppolChannel') + ' ' +  docData.ediProvider"
					:has-receivers-data="false"
				/>
			</div>
			<!-- Print -->
			<div class="mb-2"
				 v-if="(this.$store.getters.getDocumentDetailData.held === undefined || this.$store.getters.getDocumentDetailData.held === false) && docData.print">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.printStatuses.title') }}</h2>
				<div>
					<div v-for="(status, index) in docData.print" :key=index>
						<status-item :item=status itemType='print'/>
					</div>
				</div>
			</div>
			<div v-else-if="this.$store.getters.getDocumentDetailData.held === true && docData.print">
				<hold-information
					:title="$t('documentDetail.documentStatusesInfo.printStatuses.title')"
					:description="$t('documentDetail.documentStatusesInfo.printStatuses.onHold')"
					:has-receivers-data="false"
				/>
			</div>
			<!--Payment status-->
			<div v-if="docData.paymentUpdatesRaw && paymentStatus" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.paymentInfo') }}</h2>
				<div v-for="(status, index) in paymentInfoFullStatus" :key="index">
					<div class="flex flex-row items-center">
						<label-with-icon
							icon-name="info"
							:label="status.paymentStatusText"
							:icon-severity-color="status.error ? 'error' : 'info'"
							:margin-left="10"
							:font-size-icon="20"
						/>
						<label-with-icon
							v-if="status.paymentStatus !== 'OPEN'"
							icon-name="calendar_month"
							:label="status.date"
							icon-severity-color="info"
							:margin-left="5"
							:outlined="false"
						/>
					</div>
					<p class="ml-6">{{ status.message }}</p>
				</div>
			</div>
			<!-- attachments -->
			<div v-if="attachmentFilesDisplay" class="mb-2">
				<div class="flex flex-row items-center">
					<h2 class="text-base font-bold with-icon">
						{{ $t('documentDetail.documentStatusesInfo.attachments.title') }}</h2>
					<div class="tooltip-outer">
						<i v-if="docStatusData.attachmentMerged" class="icon icon-notification info smaller-icon"> </i>
						<span class="tooltip-inner" style="font-size: 14px; font-weight: normal">
							{{ $t('tooltips.attachmentsMerged') }}
						</span>
					</div>
				</div>

				<div
					v-for="attachFile in docData.attachmentFileNames"
					:key="attachFile.displayedName"
				>
					<div class="ml-3">
						<i class="icon icon-attachment"/>
						<a
							class="attachment-file-link"
							:href="attachFile.href"
							target="_blank"
						> {{ attachFile.displayedName }}</a>
					</div>
				</div>
			</div>
			<!-- inserts -->
			<div v-if="insertsFilesDisplay" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.inserts.title') }}</h2>
				<div v-for="insertsFile in docData.insertsFileNames" :key="insertsFile.displayedName">
					<div class="ml-3">
						<i class="icon icon-attachment"/>
						<a class="attachment-file-link" :href="insertsFile.href"> {{ insertsFile.displayedName }}</a>
					</div>
				</div>
			</div>

			<!--choice Onboarding link-->
			<div v-if="onboarding" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.onboardingLink') }}</h2>
				<div class="">
					<div class="channel-choice-outer">
						<div class="channel-choice">
							<div class="description" @click="copyOnboardingLink">
								<label-with-icon
									:margin-left="7"
									icon-name="public"
									:label="onboardingShortLink"
								/>
							</div>
							<div class="tooltip tooltip-channel-choice">{{ $t('tooltips.clickToCopy') }}</div>
						</div>
					</div>
				</div>
			</div>
			<!--Public link-->
			<div v-if="publiclink" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.publicLink') }}</h2>
				<div>
					<div class="channel-choice-outer">
						<div class="channel-choice">
							<div class="description" @click="copyPublicLink">
								<label-with-icon
									:margin-left="7"
									icon-name="public"
									:label="publicShortLink"
								/>
							</div>
							<div class="tooltip tooltip-channel-choice">{{ $t('tooltips.clickToCopy') }}</div>
						</div>
					</div>
				</div>
			</div>
			<!--Channel choice -->
			<div v-if="channelChoice" class="mb-2">
				<h2 class="text-base font-bold">{{ $t('documentDetail.documentStatusesInfo.channelChoiceLink') }}</h2>
				<div class="channel-choice-outer">
					<div class="channel-choice">
						<div class="description" @click="copyChannelChoiceLink">
							<label-with-icon
								:margin-left="7"
								icon-name="public"
								:label="channelChoiceShortLink"
							/>
						</div>
						<div class="tooltip tooltip-channel-choice">{{ $t('tooltips.clickToCopy') }}</div>
					</div>
				</div>
			</div>
		</div>
		<confirmation-with-props
			:description="i18n.t('general.resendThisDocument')"
			:show-dialog="showConfirmationResend"
			@approve-action="showResendDocument = true; showConfirmationResend = false"
			@cancel-action="showConfirmationResend = false"
		/>
		<resend-document
			v-if="showResendDocument"
			:docId="[docId]"
			:legalEntityId="legalEntityId"
			:docsData="[{docId: docId, legalEntityId: legalEntityId}]"
			:show-resend-document="showResendDocument"
			:docs-to-resend="[this.$store.state.documentDetailData]"
			@close-pop-up="showResendDocument = false"
			:email="reactivatedEmail"
		/>
	</div>
</template>

<script>
import DocumentInfoExtended from "../ui/DocumentInfoExtended.vue"
import i18n from "../../../i18n"
import userGroups from "../../../userGoups"
import moment from 'moment'
import StatusItem from "../../UI/StatusItem.vue"
import HoldInformation from "./HoldInformation.vue"
import LabelWithIcon from "../../UI/LabelWithIcon.vue"
import ResendDocument from "../resend/ResendDocument.vue"
import ConfirmationWithProps from "../../UI/ConfirmationWithProps.vue"

export default {
	name: "DocumentStatuses",
	mounted() {
		this.checkEmailErrors()
	},
	props: {
		docStatusData: {
			type: Object
		},
		noHeader: {
			type: Boolean,
			default: false
		},
		docId: String,
		legalEntityId: String
	},
	components: {
		ConfirmationWithProps,
		LabelWithIcon, HoldInformation, DocumentInfoExtended, StatusItem, ResendDocument
	},
	data() {
		return {
			docData: this.docStatusData,
			i18n: i18n,
			userGroups: userGroups,
			channelChoiceLink: '',
			channelChoiceShortLink: '',
			onboardingLink: '',
			onboardingShortLink: '',
			publicShortLink: '',
			publicLink: '',
			showConfirmationResend: false,
			showResendDocument: false,
			reactivatedEmail: undefined,
			regexEdiParticipant: /(\d{4}:[\w]+)\s/
		}
	},
	computed: {
		displayHandledError() {
			if (this.$route.name === 'public') return false
			return !userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)
		},
		showHoldRelease() {
			return !!this.$store.state.supplierGroup.data.holdRelease && this.$store.getters.getDocumentDetailData.held !== undefined
		},
		textHoldRelease() {
			if (this.$store.state.supplierGroup.data.holdRelease && this.$store.getters.getDocumentDetailData.held !== undefined) {
				if (this.$store.state.supplierGroup.data.holdRelease && this.$store.getters.getDocumentDetailData.held) {
					return i18n.t('documentDetail.documentStatusesInfo.documentWasHeld')
				}
			}
			return ''
		},
		paymentStatus() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage?.configPortal?.showPaymentStatus) {
				return this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.showPaymentStatus
			}
			return false
		},
		displayOnPublic() {
			return this.$route.name !== "public"
		},
		displayTracking() {
			const currentDate = new Date()
			let sixMonthsAgo = new Date()
			let invoiceDate = new Date(this.$store.state.documentDetailData.createdOn)
			moment(sixMonthsAgo.setMonth(currentDate.getMonth() - 6)).format('DD/MM/YYYY')
			return invoiceDate >= sixMonthsAgo
		},
		displayReactivateEmail() {
			return !!(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))
		},
		onboarding() {
			if (this.$route.name === 'public') {
				return false
			}

			if (this.docData.onboardingLink !== '' && this.docData.onboardingLink !== undefined
				&& (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))) {

				if (this.isDocValid()) {
					this.setOnboardingLink(this.docData.onboardingLink)
					this.setOnboardingShortLink(this.docData.onboardingLink)
					return true
				}
				return false
			}
			return false
		},
		publiclink() {
			if (this.$route.name === 'public') {
				return false
			}
			if (this.docData.publicLink !== '' && this.docData.publicLink !== undefined
				&& (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))) {

				this.setPublicLink(this.docData.publicLink)
				this.setPublicShortLink(this.docData.publicLink)
				if (this.docData.docType === "mandateonboarding") {
					return this.isDocValid()
				}
				return true
			}
			return false
		},
		channelChoice() {
			if (this.$route.name === 'public') {
				return false
			}
			if (this.docData.channelChoiceLink !== '' && this.docData.channelChoiceLink !== undefined
				&& (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))) {

				this.setChannelChoiceLink(this.docData.channelChoiceLink)
				if (this.docData.channelChoiceLink) {
					this.setChannelChoiceShortLink(this.docData.channelChoiceLink)
				}
				return true
			}
			return false
		},

		registeredMailLink() {
			return 'https://track.bpost.cloud/btr/web/#/search?lang=' + this.$store.state.locale + '&itemCode=' + this.docData.barCode.code + '&postalCode=' + this.docStatusData.zipCode
		},
		ediStatuses() {
			let ediStatusesArray = []
			let statusNames = []
			this.docData.edi.forEach(item => statusNames.push(item.status))

			for (let index in this.docData.edi) {
				ediStatusesArray.push(this.docData.edi[index])
				if (this.docData.edi[index].status === "NOT_SENT" || this.docData.edi[index].status === "SENT") {
					for (let j = ediStatusesArray.length - 1; j >= 0; j--) {
						if (ediStatusesArray[j].status === "TEMP_FAILURE") {
							ediStatusesArray.splice(j, 1)
						}
					}
				}
			}

			let ediStatuses = []

			ediStatusesArray.forEach(status => {
				let tempItem = {
					time: status.time,
					status: '',
					error: false,
				}

				if (status.message) {
					tempItem.message = status.message
				}
				switch (status.status) {
					case "RESENT":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.resent'
						break
					case "SENT":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.sent'
						break
					case "DELIVERED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.delivered'
						break
					case "PROCESSED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.processed'
						break
					case "ERROR":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.error'
						tempItem.error = true
						break
					case "NOT_SENT":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.notSent'
						tempItem.error = true
						break
					case "ACKNOWLEDGED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.acknowledge'
						break
					case "IN_PROCESS":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.inProcess'
						break
					case "UNDER_QUERY":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.underQuery'
						break
					case "COND_ACCEPTED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.condAccepted'
						break
					case "REJECTED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.rejected'
						tempItem.error = true
						break
					case "ACCEPTED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.accepted'
						break
					case "PAID":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.paid'
						break
					case "FORWARDED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.forwarded'
						break
					case "RECEIVED":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.received'
						break
					case "TEMP_FAILURE":
						tempItem.status = 'documentDetail.documentStatusesInfo.ediStatuses.tempFailure'
						break
				}
				ediStatuses.push(tempItem)
			})

			return ediStatuses
		},
		paymentInfoFullStatus() {
			let paymentStatuses = []

			if (this.docData.paymentUpdatesRaw.length === 0) {
				let tempItem = {
					paymentStatus: "OPEN",
					date: moment(this.docData.docStatuses.createdOn).format('DD/MM/YYYY'),
					message: null
				}
				paymentStatuses.push(tempItem)
			} else {
				this.docData.paymentUpdatesRaw.forEach(updateItem => {
					let tempItem = {
						paymentStatus: updateItem.paymentStatus,
						date: moment(updateItem.date).format('DD/MM/YYYY'),
						message: updateItem.message ? updateItem.message : null
					}
					paymentStatuses.push(tempItem)
				})
			}

			if (paymentStatuses.length > 1) {
				let deleteIndex = -1
				paymentStatuses.forEach((status, index) => {
					if (status.paymentStatus === "OPEN") {
						deleteIndex = index
					}
				})
				if (deleteIndex >= 0) paymentStatuses.splice(deleteIndex, 1)
			}
			let displayedStatuses = []

			paymentStatuses.forEach(paymentStatus => {
				let text = ''
				let error = false
				if (paymentStatus.paymentStatus === "OPEN") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.open')
				}
				if (paymentStatus.paymentStatus === "PAID") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.paid')
				}
				if (paymentStatus.paymentStatus === "PAID_ONLINE") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.paidOnLine')
				}
				if (paymentStatus.paymentStatus === "PAYMENT_CONFIRMED") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.confirmed') + ' ' + this.$store.state.supplierGroup.data.name
				}
				if (paymentStatus.paymentStatus === "PENDING_TRAN") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.pendingTran')
				}
				if (paymentStatus.paymentStatus === "PENDING") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.pending')
				}
				if (paymentStatus.paymentStatus === "PAID_TRAN") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.paidTran')
				}
				if (paymentStatus.paymentStatus === "ERROR_TEMP_TRAN") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.errorTempTran')
					error = true
				}
				if (paymentStatus.paymentStatus === "ERROR_TRAN") {
					text = i18n.t('documentDetail.documentStatusesInfo.paymentStatuses.errorTran')
					error = true
				}

				displayedStatuses.push({
					paymentStatus: paymentStatus.paymentStatus,
					paymentStatusText: text,
					date: paymentStatus.date,
					error: error,
					message: paymentStatus.message ? paymentStatus.message : null
				})
			})

			return displayedStatuses
		},

		visibilityIcon() {
			return this.docData.docStatuses.visibility === "visible"
		},
		documentVisibility() {
			if (this.docData.docStatuses.visibility === "visible") {
				return i18n.t('documentDetail.documentStatusesInfo.documentIsVisible')
			}
			return i18n.t('documentDetail.documentStatusesInfo.documentIsNotVisible')
		},
		attachmentFilesDisplay() {
			return this.docData.attachmentFileNames.length
		},
		insertsFilesDisplay() {
			return this.docData.insertsFileNames.length
		}
	},
	methods: {
		isOutOfOffice(type) {
			let outOfOffice = false
			this.docData[type].errorEmail.forEach(error => {
				if (error.description.toLowerCase().includes('out of office')) outOfOffice = true
			})
			return !!outOfOffice
		},
		isDocValid() {
			let endDate = new Date(this.docData.validityEnd)
			let today = new Date().getTime()
			endDate.setHours(23, 59, 59, 999)
			let validityEnd = new Date(endDate).getTime()  //milliseconds

			return validityEnd >= today
		},
		displayNotification(name) {
			return name === 'Hard bounce'
		},
		translateEdiErrors(message) {
			if (message.includes('Wrapped Phase4Exception')) {
				return 'documentDetail.documentStatusesInfo.ediErrors.wrappedPhase'
			} else if (message.includes('is a Hermes-only Participant')) {
				return 'documentDetail.documentStatusesInfo.ediErrors.hermesOnlyParticipant'
			} else if (message.includes('not reachable through Peppol Network')) {
				return 'documentDetail.documentStatusesInfo.ediErrors.notReachableThroughPeppol'
			}
		},
		translateMailErrors(message, email) {
			if (message.includes('DNS error')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.DNSError'
			} else if (message.includes('INVALID_EMAIL')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.invalidEmail'
			} else if (message.includes('Hard bounce')) {
				if (!this.isInPostMark('attachBounces', email)) {
					return 'documentDetail.documentStatusesInfo.mailErrors.hardBounceAlreadyReactivated'
				} else return 'documentDetail.documentStatusesInfo.mailErrors.hardBounce'
			} else if (message.includes('Soft bounce')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.softBounce'
			} else if (message.includes('Message delayed')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.messageDelayed'
			} else if (message.includes('ISP block')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.ISPBlock'
			} else if (message.includes('Spam notification')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.spamNotification'
			} else if (message.includes('Auto responder')) {
				return 'documentDetail.documentStatusesInfo.mailErrors.outOfOffice'
			}
		},
		inactiveErrorReason(itemReason, field, email) {
			let reasonText = itemReason
			let isInPostMark = this.isInPostMark(field, email)
			let reason = ''
			this.$store.state.emailSuppression.check[field].forEach(item => {

				if (item.email === email) {
					reason = item.reason
				}
			})

			if (itemReason === "INACTIVE_RECIPIENT" && isInPostMark) {
				if (reason === "HardBounce") {
					reasonText = i18n.t('emailErrors.inactiveMailIsInPostMarkReasonHardBounce')
				}
				if (reason === "SpamComplaint") {
					reasonText = i18n.t('emailErrors.inactiveMailIsInPostMarkReasonSpam')
				}
			} else if (itemReason === 'INVALID_EMAIL') {
				reasonText = i18n.t('documentDetail.documentStatusesInfo.mailErrors.invalidEmail')
			} else if (itemReason === 'OTHER') {
				reasonText = i18n.t('documentDetail.documentStatusesInfo.mailErrors.other')
			} else if (itemReason === "INACTIVE_RECIPIENT"){
				reasonText = i18n.t('documentDetail.documentStatusesInfo.mailErrors.inactiveRecipient')

			}
			return reasonText
		},
		isInactiveRecipient(reason) {
			return (reason === "INACTIVE_RECIPIENT")
		},
		async deleteSuppression(email) {
			await this.$store.dispatch('users/reactivateEmail', {email: email})
			this.showConfirmationResend = true
			this.$store.state.generalNotification.documentDetailEmailReactivated = false
			this.reactivatedEmail = email

		},
		checkReason(errorType, email, reason) {
			let display = false
			this.$store.state.emailSuppression.check[errorType].forEach(item => {
				if (item.email === email && item.reason.toLowerCase() === reason.toLowerCase()) {
					display = true
				}
			})
			return display
		},
		isInPostMark(errorType, email) {
			let isInPM = false
			this.$store.state.emailSuppression.check[errorType].forEach(item => {
				if (item.email === email) {
					isInPM = true
				}
			})
			return isInPM
		},
		checkEmailErrors() {
			let emailErrors = {
				attachBounces: [],
				attachSpam: [],
				attachInactive: [],
				linkBounces: [],
				linkSpam: [],
				linkInactive: [],
			}

			if (this.docData.emailWithAttachment) {
				if (this.docData.emailWithAttachment.errorEmail) {
					this.docData.emailWithAttachment.errorEmail.forEach(item => {
						emailErrors.attachBounces.push(item.email)
					})
				}
				if (this.docData.emailWithAttachment.spam) {
					this.docData.emailWithAttachment.spam.forEach(item => {
						emailErrors.attachSpam.push(item.email)
					})
				}
				if (this.docData.emailWithAttachment.inactiveEmail) {
					this.docData.emailWithAttachment.inactiveEmail.forEach(item => {
						emailErrors.attachInactive.push(item.email)
					})
				}
			}
			if (this.docData.linkEmail) {
				if (this.docData.linkEmail.errorEmail) {
					this.docData.linkEmail.errorEmail.forEach(item => {
						emailErrors.linkBounces.push(item.email)
					})
				}
				if (this.docData.linkEmail.spam) {
					this.docData.linkEmail.spam.forEach(item => {
						emailErrors.linkSpam.push(item.email)
					})
				}
				if (this.docData.linkEmail.inactiveEmail) {
					this.docData.linkEmail.inactiveEmail.forEach(item => {
						emailErrors.linkInactive.push(item.email)
					})
				}
			}

			if ((userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))
				&& this.$store.state.currentUserData.data.group !== "SupplierLimitedSubUser") {
				let keys = Object.keys(emailErrors)
				keys.forEach((key) => {
					emailErrors[key].forEach(email => {
						this.$store.dispatch("documents/checkEmailPostMark", {
							email: email,
							errorType: key
						})
					})
				})
			}
		},
		setChannelChoiceLink(link) {
			this.channelChoiceLink = link
		},
		setChannelChoiceShortLink(link) {
			let shortLink = link
			if (link) {
				shortLink = 'https://' + shortLink.slice(0, 50) + '...'
				this.channelChoiceShortLink = shortLink
			}
		},
		setOnboardingLink(link) {
			this.onboardingLink = link
		},
		setPublicLink(link) {
			this.publicLink = link
		},
		setOnboardingShortLink(link) {
			let shortLink = link
			if (link) {
				shortLink = 'https://' + shortLink.slice(0, 50) + '...'
				this.onboardingShortLink = shortLink
			}
		},
		setPublicShortLink(link) {
			let shortLink = link
			if (link) {
				shortLink = shortLink.slice(0, 50) + '...'
				this.publicShortLink = shortLink
			}
		},
		copyOnboardingLink() {
			navigator.clipboard.writeText('https://' + this.onboardingLink)
		},
		copyPublicLink() {
			navigator.clipboard.writeText(this.publicLink)
		},
		copyChannelChoiceLink() {
			navigator.clipboard.writeText('https://' + this.channelChoiceLink)
		},
		printStatus(printStatus) {
			switch (printStatus) {
				case 'QUEUED':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.queued')
				case 'SENT_TO_PRINTER':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.sentToPrinter')
				case 'PRINTER_PROCESSING':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.printerProcessing')
				case 'PROCESSED_BY_PRINTER':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.processedByPrinter')
				case 'RE_PROCESSED_BY_PRINTER':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.reProcessedByPrinter')
				case 'RE_SENT_TO_PRINTER':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.reSentToPrinter')
				case 'RE_QUEUED':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.reQueued')
				case 'PRINTER_RETURNED':
					return i18n.t('documentDetail.documentStatusesInfo.printStatuses.printerReturned')
			}
			return printStatus
		}
	}
}
</script>

<style scoped>
.document-info {
	padding-left: var(--generalPaddings);
}

.status-title {
	display: grid;
	grid-template-columns: 25px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	border-bottom: 1px solid lightgrey;
	padding-bottom: 10px;
	margin-top: 10px;
}

.status-title i {
	font-size: 20px;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	align-items: center;
}

.info-color {
	color: var(--infoColor);
}

.icon-attachment {
	color: var(--infoColor);
	margin-right: 5px;
}

.attachment-file-link {
	color: var(--infoColor);
	cursor: pointer;
}

.attachment-file-link:hover {
	text-decoration: underline;
}

.displayNone {
	display: none;
}

.description {
	color: var(--theme-primary-500);
	font-size: 14px;
	padding-left: 5px;
	margin-right: 20px;
}

i, .description {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	font-size: 14px;
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
	.status-title h1 {
		font-size: 1.3rem;
	}
}

.tooltip {
	padding: 0 4px;
	justify-self: start;
}

.with-icon {
	display: flex;
	align-items: center;
}

.smaller-icon {
	font-size: 16px;
	margin-left: 10px;
}

.channel-choice:hover .tooltip-channel-choice {
	display: grid;
}

.channel-choice {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-self: start;
	cursor: pointer;
}

.channel-choice .description, .channel-choice .tooltip {
	grid-column: 1/2;
	grid-row: 1/2;
}

.channel-choice .description {
	justify-self: start;
}

.channel-choice .tooltip {
	justify-self: center;
}

.channel-choice-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
}

.reactivate-email {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: end;
}

.material-icons {
	font-size: 20px;
	margin-top: 8px;
}

.align-icon {
	display: flex;
	flex-direction: row;
	align-content: center;
}

@media (max-width: 1000px) {
	.reactivate-email {
		grid-column: 1/2;
		grid-row: 4/5;
	}
}

.email-info {
	margin-left: 20px;
	justify-self: start;
}

.justify-start {
	justify-self: start;
}

i {
	margin-right: 4px;
}

.margin0 {
	margin-left: 0;
}

.edi-provider {
	font-size: 16px;
	margin-left: 20px;
}

.padding-left {
	margin-left: var(--generalPaddings);
}

@media (max-width: 550px) {
	.document-info {
		padding-left: 0;
	}

	.edi-provider, .padding-left {
		margin-left: calc(0.5 * var(--generalPaddings));
	}
}
</style>