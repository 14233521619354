import cash from "cash-dom";

export default function selectColumn(){
	return  {
		title: `<input class ="common displayColumn customCheckbox marginleft-5" type=checkbox id="selectAllDocuments">`,
		field: 'docId',
		width: '2%',
		hozAlign: "center",
		cssClass: 'text-centered',
		headerSort: false,
		formatter(cell) {
			const a = cash(`<div>
                                          		<input 	
                                          			type=checkbox
                                            		Value=${cell.getData().docId} class="downloadCheckbox common customCheckbox"
                                            		data-leId=${cell.getData().legalEntityId}
                                            		data-noVat=${cell.getData().noVat}
                                            	/>
                          					</div>`);
			return a[0]
		}
	}
}