import router from "../../router"
import axios from "axios"

export default {
	displayDetailPage(context, payload) {
		let documentDeatilData = Object.assign({}, payload.docData)
		context.commit('setDocumentDetailData', documentDeatilData, {root: true})
		if (payload.mouseEvent === "left") {
			let pageLink = '/overview/' + context.rootGetters.getDocumentDetailData.data.docId
			router.push(pageLink)
		} else {
			let link = '/overview/' + context.rootGetters.getDocumentDetailData.data.docId
			window.open(link, '_blank')
		}
	},
	async getDocumentDetailData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.getDocument + '/' + payload.documentId
		return await axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			context.commit('setDocumentDetailData', res.data.data.data, {root: true})
			context.commit('setDocumentDetailOpTableInfo', res.data.data.opTableInfo, {root: true})
		})
			.catch(() => {
				router.push('/notFound')
			})
	},
	fetchPublicDocumentData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.getPublicDocument + payload.publicDocId;
		return axios.get(url, {
				params: {
					'access_code': payload.accessCode,
				}
			}
		).then((res) => context.commit('setPublicDocumentsInfo', res.data.data.data, {root: true}))
			.catch(() => router.push('/notFound'))
	},
	getProcessedDocumentData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.processedDocumentData.replace(":documentId", payload)
		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
			context.commit('setProcessedDocumentData', res.data.data.postDropDoc, {root: true})
		}).catch(err => {
			console.error(err)
		})

	},
	readPublicDocument(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.readDocument + payload.documentId + '/user/' + payload.user

		return axios.get(url,
		).catch(err => console.error(err))
	},

	deleteDocument(context, payload) {
		let url = context.rootState.domainName + '/api/' + payload.legalEntityId + '/documents/' + payload.docId
		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit('documentDeleted', true, {root: true})
			router.push('/')
		})
			.catch(() => {
				context.rootState.errorNotification.type = "DELETEDOCUMENT"
				context.rootState.errorNotification.display = true
			})
	},
	resendEmail(context, payload) {
		let url

		if (payload.emailInfo.length === undefined ){
			url = context.rootState.domainName + '/api/documents/' + payload.docId + '/resend-mail'
			payload = payload.emailInfo
		} else {
			url = context.rootState.domainName + '/api/documents/resend-mail'
		}

		return axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			},
		).catch(() => {
			context.commit('displayResendDocument', false, {root: true})
			context.rootState.errorNotification.type = "RESENDEMAIL"
			context.rootState.errorNotification.display = true
		})
	},
	resendEdi(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.resendEdi.replace(':documentId', payload.docId)
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				context.commit('displayResendDocument', false, {root: true})
			}
		).catch(() => {
			context.commit('displayResendDocument', false, {root: true})
			context.rootState.errorNotification.display = true
		})
	},
	reprintDocument(context, payload) {
		let url = context.rootState.domainName + '/api/documents/' + payload.docId + '/reprint'
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit('displayResendDocument', false, {root: true}))
			.catch(() => {
				context.commit('displayResendDocument', false)
				context.rootState.errorNotification.display = true
			})
	},

	downloadUbl(context, payload) {
		let url = context.rootState.domainName + '/api/documents/' + payload.ids + '/attachment/ubl'
		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			let url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', payload.number + '_UBL.xml')
			document.body.appendChild(link)
			link.click()

		}).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},
	downloadPdf(context, payload) {
		let url = ''
		if (payload.route === "documentDetail") {
			url = context.rootState.domainName + '/api/documents/' + payload.ids + '/pdf'
		}
		if (payload.route === "public") {
			url = context.rootState.domainName + '/api/documents/public/' + payload.ids + '/pdf?access_code=' + payload.accessCode
		}
		return axios.get(url,
			{
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
			.then(res => {
				let url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', payload.docName)
				document.body.appendChild(link)
				link.click()
			})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},
	downloadJson(context, payload) {
		const url = context.rootState.domainName + '/api/documents/' + payload.docId + '/json'

		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			let url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', payload.docName)
			document.body.appendChild(link)
			link.click()
		})
	},
	downloadDocumentSet(context, payload) {
		let url = ''
		if (payload.route === 'documentDetail') {
			url = context.rootState.domainName + '/api/documents/' + payload.ids + '/zip'
		}
		if (payload.route === 'public') {
			url = context.rootState.domainName + '/api/documents/public/' + payload.ids + '/zip?access_code=' + payload.accessCode
		}
		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(res => {
				let url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'document_' + payload.docNumber + '.zip')
				document.body.appendChild(link)
				link.click()
			})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},
	downloadZip(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.downloadDocumentsZip
		let dowloadDocumentIds = Object.values(Object.assign({}, payload.ids))
		let mainDocumentOnly
		if (payload.type === "pdf") {
			mainDocumentOnly = true
		}
		if (payload.type === "ubl") {
			mainDocumentOnly = false
		}
		return axios.post(url,
			{
				ids: dowloadDocumentIds,
				mainDocumentOnly: mainDocumentOnly
			},
			{
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((response) => {
			let url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'documents.zip')
			document.body.appendChild(link)
			link.click()
		}).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},

	async handleErrors(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.handleErrors
		await axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				if (payload.routeName && payload.routeName === 'docDetail') {
					context.commit('documentDetailErrorHandled', {root: true})
				}
				if (payload.routeName && payload.routeName === 'processedDocument') {
					context.commit('processedDocumentErrorHandled', null, {root: true})
				}
				context.commit("setDocumentErrorHandled", true, {root: true})
			}
		).catch(() => {
			context.rootState.errorNotification.display = true
		})

		if (payload.formData.docIds.length === 1){
			await context.dispatch('getDocumentDetailData', {documentId: payload.formData.docIds[0]})
		}
	},
	async addAttachment(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.addAttachment.replace(':docId', payload.docId)
		await axios.post(url, payload.filesToSend, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
					'Content-Type': 'multipart/form-data',
				}
			}
		)
		await context.dispatch('getDocumentDetailData', {documentId: payload.docId})
	},
	setInvoicePaid(context, payload) {
		return axios.post(context.rootState.domainName + context.rootState.API.setPaymentStatusPaid, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(() => {
			const test = context.rootGetters.getDocumentDetailData

			if (test.channels.payment?.paymentUpdates === undefined) {
				test.channels.payment = {
					paymentUpdates: [{
						date: new Date().toISOString(),
						paymentStatus: 'PAYMENT_CONFIRMED'
					}]
				}
			} else {
				test.channels.payment.paymentUpdates.push({
					date: new Date().toISOString(),
					paymentStatus: 'PAYMENT_CONFIRMED'
				})
			}
			context.commit("setDocumentDetailData", test, {root: true})
		}).catch((error) => {
			console.error(error)
			context.rootState.errorNotification.display = true
		})
	},
	setInvoiceUnpaid(context, payload) {
		return axios.post(context.rootState.domainName + context.rootState.API.setPaymentStatusUnpaid, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(() => {
			const data = context.rootGetters.getDocumentDetailData
			const deletePayment = data.channels.payment.paymentUpdates.find(payment => payment.paymentStatus === "PAYMENT_CONFIRMED")

			if (deletePayment) {
				data.channels.payment.paymentUpdates.splice(data.channels.payment.paymentUpdates.indexOf(deletePayment), 1)
			}
			context.commit("setDocumentDetailData", data, {root: true})
		}).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},
	async toggleVisibility(context, payload) {
		return await axios.post(context.rootState.domainName + context.rootState.API.toggleVisibility, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
	},
	async releaseDocument(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.releaseDocument.replace(':docId', payload.docId)
		return axios.post(url, {attachmentsAdded: true},
			{
				headers: {
					'authorization': "bearer " + context.rootState.token,
				}
			}
		)
	},
	markHandled(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.handleFailedTransaction

		return axios.post(url, payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			context.commit("setGeneralNotificationText", {
				field: "markHandledFailedTransaction",
				text: 'OK'
			}, {root: true})
			context.commit('displayNotification', true, {root: true})
			context.commit("failedTransactionUpdated", true, {root: true})
		}).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},
	async getParticipantId(context, payload){
		let url = context.rootState.domainName + context.rootState.API.getParticipantId.replace(':documentId', payload.docId)

		return await axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch(err => {
			console.log("get participant: " + err)
			context.rootState.errorNotification.display = true
		})
	},

	readFileSize(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.getDocument + '/' + payload.documentId + "/file-sizes"
		return axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then((res) => {
				context.commit('setDocumentFileSizes', {
					pdfSize: res.data.data.pdfSize,
					combinedSize: res.data.data.combinedSize
				}, {root: true})
			}).catch((err) => console.error(err))
	},
	readDocument(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.readDocument + payload.documentId + '/user/' + payload.userEmail
		return axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch((err) => console.error(err))
	},
	checkEmailPostMark(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.retrieveSuppressions;

		return axios.post(url, {email: payload.email}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		).then((res) => {
			if (res.data.data.suppressions.length) {
				context.commit('setEmailSuppressionsCheck', {
					errorType: payload.errorType,
					email: payload.email,
					reason: res.data.data.suppressions[0].reason
				}, {root: true})
			}
		})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	}
}