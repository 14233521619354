export default function mergeAddress(docData, docType) {
	let address

	if (docType === 'invoice' || docType === 'creditnote') address = docData.billingData.billingTo.customerAddress
	else if (docType === 'dunning' || docType === 'salarySlip' || docType === 'carinsurance') return ''
	else if (docType === 'mandateonboarding') address = docData.mandateOnboardingData.postalAddress

	let mergedAddress = address.streetName
	if (address.houseNumber) mergedAddress = mergedAddress + ' ' + address.houseNumber

	mergedAddress = mergedAddress + ', ' + address.zip + ' ' + address.city + ', ' + address.countryCode
	return mergedAddress
}
