<template>
	<pop-up-window
		:show="this.$store.state.userManagement.displayResetPassword"
		:showClose=false
		@close="closeResetPassword"
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('management.resetPassword.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info divider">
				<h2 class="text-center">
					{{ email }} {{ resetText }}
				</h2>
				<h2 v-if="!this.$store.state.resetLoggedInUserPassword" class="text-center color-grey"
					style="font-size: 16px">{{ $t('management.resetPassword.extraInfo') }}</h2>
			</div>
			<div class="flex justify-center">
				<input
					type='text'
					v-model="newPassword"
					:placeholder="$t('placeholders.password')"
					class="form-control w-2/3"
					style="margin-right: 10px; height: 37px; font-size: 16px"
				/>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
							@click="closeResetPassword">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="resetPassword">
						{{ $t('buttons.reset') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import i18n from '../../../i18n'

export default {
	name: "ResetPassword",
	components: {PopUpWindow},
	emits: ['passwordIsReset'],
	data() {
		return {
			i18n: i18n,
			newPassword: ""
		}
	},
	computed: {
		email() {
			if (this.$store.state.resetLoggedInUserPassword) {
				return this.$store.state.currentUserData.data.email
			}
			return this.$store.state.userManagement.userData.email
		},
		resetText() {
			if (this.$store.state.resetLoggedInUserPassword) {
				return i18n.t('management.resetPassword.resetLoggedInUserPassword')
			}
			return i18n.t('management.resetPassword.text')
		}
	},
	methods: {
		closeResetPassword() {
			this.newPassword = ""
			this.$store.commit('displayResetPassword', false)
		},
		resetPassword() {
			if (!this.$store.state.resetLoggedInUserPassword) {
				let formData = {
					formData: {
						language: this.$store.state.userManagement.userData.language,
						userEmail: this.$store.state.userManagement.userData.email,
					}
				}

				if (this.newPassword.trim() !== "") {
					formData.formData.tempPassword = this.newPassword.trim()
				}
				this.$store.dispatch('resetPasswordOfAUser', formData)
			} else {
				let formData = {
					fromData: {
						language: this.$store.state.currentUserData.data.language,
						userEmail: this.$store.state.currentUserData.data.email,
					}
				}
				if (this.newPassword.trim() !== "") {
					formData.formData.tempPassword = this.newPassword.trim()
				}
				this.$store.dispatch('resetPasswordOfAUser', formData)

			}
			this.$store.commit('resetLoggedInUserPassword', false)
			this.closeResetPassword()
			this.$emit('passwordIsReset')
		}
	}
}
</script>

<style scoped>
.divider {
	padding-bottom: calc(0.5 * var(--generalPaddings));
	border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}
</style>
