import cash from "cash-dom";

export default function visibilityColumn() {
	return {
		title: 'VISIBILITY',
		field: 'visibility',
		hozAlign: "center",
		cssClass: 'text-centered',
		headerSort: false,
		width: '5%',
		formatter(cell) {
			const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                            				<p> ${cell.getData().visibility} </p>
                                    					</div> `)
			return a[0]
		}
	}
}