<template>
	<div class="mb-2">
		<div class="flex flex-row items-center">
			<label-with-icon
				:margin-left="10"
				icon-name="print"
				:label="text"
				icon-severity-color="info"
				:outlined="false"
			/>
			<label-with-icon
				:margin-left="5"
				icon-name="calendar_month"
				:label="item.time"
				icon-severity-color="info"
				:outlined="false"
			/>
		</div>
		<div class="error-message" v-if="item.message && translatePrintErrors(item.message) !== '' && displayError">
			<div class="align-icon">
				<label-with-icon
					:margin-left="20"
					icon-name="error_outline"
					:label="$t(translatePrintErrors(item.message)) !== '' ? $t(translatePrintErrors(item.message)) : item.message "
					icon-severity-color="error"
					:font-size-icon="20"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import i18n from "../../i18n"
import LabelWithIcon from "../UI/LabelWithIcon.vue"

export default {
	name: "StatusItem",
	components: {LabelWithIcon},
	props: {
		item: {
			type: Object,
			required: true
		},
		itemType: {
			type: String,
			required: false,
			default: 'print'
		},
	},
	mounted() {
		this.setTranslations()
	},
	data() {
		return {
			text: '',
			i18n: i18n
		}
	},
	watch: {
		locale() {
			this.setTranslations()
		},
		item: {
			handler() {
				this.setTranslations()
			},
			deep: true
		}
	},

	computed: {
		displayError() {
			return !this.item.message.includes('warning 5010')
		},
		locale() {
			return this.$store.state.locale
		}
	},
	methods: {
		setTranslations() {
			if (this.itemType === "print") {
				let key = 'dropdowns.printStatuses.' + this.item.status.toLowerCase()
				this.text = i18n.t(key)
			}
		},
		translatePrintErrors(message) {
			if (message.includes('INCOMPLETE/INCORRECT ADDRESS')){
				return 'documentDetail.documentStatusesInfo.printErrors.incorrectAddress'
			} else if (message.toLowerCase().includes('do not receive mails at')){
				return 'documentDetail.documentStatusesInfo.printErrors.notReceiveIndicatedAddress'
			} else if (message.includes('BOX NOT STANDARD')){
				return 'documentDetail.documentStatusesInfo.printErrors.boxNotStandard'
			}
			else return ''
		},
	}
}
</script>

<style scoped>
.align-icon {
	display: flex;
	flex-direction: row;
	align-content: center;
}

@media only screen and (max-width: 1000px) {
	span {
		font-size: 14px;
	}
}
</style>