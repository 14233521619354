export default function calcCustomerPOReference(documentReferences) {
	let customerPOReference = ""
	if(documentReferences){
		documentReferences.forEach(ref => {
			if (ref.description === "CustomerPOReference") {
				customerPOReference = ref.value
			}
		})
	}
	return customerPOReference
}
